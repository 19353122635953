import { Col, Row, Skeleton } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../component/Button";
import Card from "../../component/Card";
import {
  fetchAllLabRequests,
  labRequestSelector,
} from "../../redux/reducers/labRequests";
import { ReactComponent as EncounterIcon } from "../../assets/icons/doctor.svg";
import { ReactComponent as ServiceIcon } from "../../assets/icons/healthcare.svg";

import {
  DashboardWrapper,
  ContentWrapper,
  StatsCardWrapper,
  OverviewIntroCardWrapper,
} from "./styles";
import LabRequestsTable from "../LabRequests/containers/LabRequestsTable";
import { authSelector, fetchProfile } from "../../redux/reducers/auth";

const Dashboard = ({ dashboardMode }: any) => {
  const dispatch = useDispatch();
  const { currentUser, isProfileLoading } = useSelector(authSelector);
  const { investigations, name } = currentUser || {};

  // const { users, isUsersLoading } = useSelector(userSelector);
  const { labRequests, isLabRequestsLoading } = useSelector(labRequestSelector);

  useEffect(() => {
    dispatch(fetchProfile())
    dispatch(fetchAllLabRequests());
  }, [dispatch]);

  const statsData2 = [
    {
      title: "Service requests",
      value: labRequests?.length || 0,
      loading: isLabRequestsLoading,
      actionText: "View service requests",
      icon: <ServiceIcon />,
      url: "/service-requests",
      dashboardMode: ["integration", "connect"],
    }
  ];

  const pendingTasks = labRequests?.filter((item) => item.status === "pending");

  return (
    <DashboardWrapper>
      <ContentWrapper>
        <Row gutter={0}>
          <Col span={24} className="overview-intro-pane">
            <OverviewIntroCardWrapper>
              <div className="circles" />
              <div className="inner">
                <Row align="middle">
                  <Col xs={24} md={12}>
                    <h3>
                      Welcome to your dashboard, {name}!
                    </h3>
                    <p>
                      Message Center: You have {pendingTasks.length} pending tasks to complete
                    </p>
                  </Col>
                  <Col xs={24} md={12} style={{ textAlign: "right" }}>
                    <Link to="/manage-account">
                      <Button type="secondary">Manage Account</Button>
                    </Link>
                  </Col>
                </Row>
              </div>
            </OverviewIntroCardWrapper>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Row gutter={16} equal-heights={true}>
              <Col xs={24} md={12} style={{ height: "100%" }}>
                <StatsCardItem
                  title="My Services"
                  icon={<EncounterIcon />}
                  loading={isProfileLoading}
                  url={"/my-services"}
                  value={`${(investigations?.length || 0).toLocaleString()}`}
                  actionText={"View services"}
                />
              </Col>
              {statsData2
                .filter((item) => {
                  return item.dashboardMode.includes(dashboardMode);
                })
                .map(
                  ({ title, value, loading, actionText, url, icon }, index) => {
                    return (
                      <Col
                        xs={24}
                        md={12}
                        key={index}
                        style={{ height: "100%" }}
                      >
                        <StatsCardItem
                          title={title}
                          value={value}
                          loading={loading}
                          icon={icon}
                          url={url}
                          actionText={actionText}
                        />
                      </Col>
                    );
                  }
                )}
            </Row>
          </Col>
        </Row>
        <Row className="encounters-pane">
          {dashboardMode === "integration" ? (
            <>
              <Col xs={0} md={12}>
                <h2>Pending requests</h2>
              </Col>
              <Col xs={0} md={12} style={{ textAlign: "right" }}>
                <Link to="/service-requests">See All</Link>
              </Col>
            </>
          ) : (
            <>
              <Col xs={0} md={12}>
                <h2>Pending requests</h2>
              </Col>
              <Col xs={0} md={12} style={{ textAlign: "right" }}>
                <Link to="/service-requests">See All</Link>
              </Col>
            </>
          )}
          <Col xs={0} md={24}>
            {dashboardMode === "integration" ? (
              <LabRequestsTable
                status="pending"
                count={5}
                hasPagination={false}
                marginTop={10}
              />
            ) : (
              <LabRequestsTable
                count={5}
                hasPagination={false}
                marginTop={10}
              />
            )}
          </Col>
        </Row>
      </ContentWrapper>
    </DashboardWrapper>
  );
};

export default Dashboard;

const StatsCardItem = ({
  title,
  value,
  loading,
  actionText,
  url,
  icon,
}: any) => {
  return (
    <StatsCardWrapper active={false} isCurrency={title === "Wallet Balance"}>
      <Card>
        {loading ? (
          <Skeleton paragraph={{ rows: 1 }} />
        ) : (
          <div className="content">
            <div className="flex">
              <div>
                <p className="title">{title}</p>
                <h2>
                  {value.toLocaleString()}
                  {title === "Reviews & Ratings" && <small>/5.0</small>}
                </h2>
              </div>
              <span className="icon">{icon}</span>
            </div>
            <span className="link">
              <Link to={url}>
                {actionText} <i className="lni lni-arrow-right"></i>
              </Link>
            </span>
          </div>
        )}
      </Card>
    </StatsCardWrapper>
  );
};
