import { Col, Popconfirm, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import Button from "component/Button";
import Card from "component/Card";
import Search from "component/Search";
import AddServiceModal from "component/AddServiceModal";
import { authSelector, fetchProfile } from "redux/reducers/auth";
import {
  deleteInvestgation,
  investigationSelector
} from "redux/reducers/investigations";

import { InvestigationTableBody, InvestigationTableWrapper } from "./styles";
interface InvestigationTableProps {
  count?: number;
  hasPagination?: boolean;
  marginTop?: number;
}

const InvestigationTable = ({
  count,
  hasPagination = true,
  marginTop = 20
}: InvestigationTableProps) => {
  const dispatch = useDispatch();
  const { currentUser, isProfileLoading } = useSelector(authSelector);
  const { deleteInvestgationsLoading } = useSelector(investigationSelector);
  const [keyword, setKeyword] = useState("");
  const [investigationsState, setInvestigationsState] = useState<any[]>([]);
  const pneumaCurrentLab = JSON.parse(
    window.localStorage.getItem("pneumaCurrentLab") || ""
  );
  const { integration } = pneumaCurrentLab || {};

  const [isAddServiceModalVisible, setIsAddServiceModalVisible] =
    useState(false);
  const { investigations } = currentUser || {};

  useEffect(() => {
    setInvestigationsState(investigations);
  }, [investigations]);

  const handleSearch = (e: any) => {
    const keyword = e.target.value;
    setKeyword(keyword);
    const _investigations = [...investigations];
    setInvestigationsState(
      _investigations.filter((item) => {
        const { name } = item;
        return name.toLowerCase().includes(keyword.toLowerCase());
      })
    );
  };

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch, integration]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        return (
          <div className='flex' style={{ alignItems: "center" }}>
            <div>
              <p style={{ marginBottom: 5 }}>{name}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category: any) => {
        return <p>{category}</p>;
      }
    },
    {
      title: "Turn Around Time",
      dataIndex: "turn_around_time",
      key: "turn_around_time",
      align: "center" as "center",
      render: (turn_around_time: string) => {
        return (
          <p style={{ textTransform: "capitalize" }}>{turn_around_time}</p>
        );
      }
    },
    {
      title: "Sample Bottle",
      dataIndex: "sample_bottle",
      key: "sample_bottle",
      align: "center" as "center",
      render: (sample_bottle: string) => {
        return <p>{sample_bottle}</p>;
      }
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price: number) => {
        return (
          <>
            <p style={{ minWidth: "60px" }}>
              NGN {(price ? price / 100 : 0).toLocaleString()}
            </p>
          </>
        );
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right" as "right",
      render: (_: any, record: any) => {
        const lab_reference_id = record?.lab_reference_id;
        return (
          <div className='action-icons' style={{ minWidth: "80px" }}>
            <span className='icon' onClick={() => setIsSelectedForEdit(record)}>
              <EditOutlined />
            </span>
            <Popconfirm
              placement='topLeft'
              title={"Are you sure you want to delete this service?"}
              onConfirm={() => handleDeleteInvestigation(lab_reference_id)}
              okText={deleteInvestgationsLoading ? "Deleting..." : "Yes"}
              cancelText='No'
            >
              <span className='icon'>
                <DeleteOutlined />
              </span>
            </Popconfirm>
          </div>
        );
      }
    }
  ];

  const [isSelectedForEdit, setIsSelectedForEdit] = useState<any>(null);

  const handleDeleteInvestigation = async (lab_reference_id: any) => {
    await dispatch(deleteInvestgation(lab_reference_id, integration));
  };

  const dataSource = investigationsState || [];
  const countText = `${investigationsState?.length || 0}`;
  return (
    <>
      {!count && (
        <Row justify='space-between' align='middle'>
          <Col xs={24} md={12}>
            <h3>
              {count ? "My Services" : "My Services"}{" "}
              <small>({countText})</small>
            </h3>
          </Col>
          <Col xs={24} md={12}>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <Search
                  placeholder='Search by service name'
                  onChange={handleSearch}
                />
              </div>
              <Button
                style={{ marginLeft: 10, height: "40px" }}
                onClick={() => setIsAddServiceModalVisible(true)}
                type='primary'
              >
                Add Investigation
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <InvestigationTableWrapper>
        <Card marginBottom={15} padding={0} style={{ marginTop }}>
          <InvestigationTableBody hasPagination={hasPagination}>
            <Table
              dataSource={dataSource}
              columns={columns}
              scroll={
                !isProfileLoading && dataSource.length > 0 ? { x: 700 } : {}
              }
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              loading={isProfileLoading}
              pagination={
                hasPagination
                  ? { position: ["bottomRight"], showLessItems: true }
                  : false
              }
            />
          </InvestigationTableBody>
          <AddServiceModal
            isSelectedForEdit={isSelectedForEdit}
            isModalVisible={isSelectedForEdit || isAddServiceModalVisible}
            handleCancel={() => {
              setIsAddServiceModalVisible(false);
              setIsSelectedForEdit(null);
            }}
          />
        </Card>
      </InvestigationTableWrapper>
    </>
  );
};

export default InvestigationTable;
