import styled from 'styled-components';

const LabRequestsDrawerWrapper = styled.div`

`;

const LabRequestsItemWrapper = styled.div`
    padding: 20px;
    border-bottom: 0.5px solid rgba(78, 102, 126, 0.35);
    &:last-child {
        border-bottom: none;
    }
    h3 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0px;
    }
    p {
        margin-top: 8px;
        font-size: 13px;
        margin-bottom: 0px;
    }
    i {
        font-size: 13px;
        font-style: italic;
        margin-bottom: 0px;
    }
`;

const LabRequestsDrawerHeader = styled.div`
    height: auto;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    span.title {
        font-size: 13px;
        opacity: 0.7;
        margin-right: 20px;
    }
    .ant-tag-checkable {
        border-radius: 6px;
        padding: 4px 15px;
        opacity: 1;
    }
    ul {
        list-style-type: none;
        list-style: none;
        margin: 0;
        padding: 0px 40px;
        li {
            display: block;
            float: left;
            padding: 20px 10px 10px 10px;
            margin-right: 44px;
            cursor: pointer;
            font-family: "DM Sans";
            font-weight: 500;
            font-size: 13px;
            text-align: left;
            color: #002e46;
            &.active {
                color: #0f9af0;
                border-bottom: 3px solid #0f9af0;
            }
        }
    }
`;

const LabRequestsDrawerBody = styled.div`
    .investigation-item {
        margin-bottom: 20px;
        box-shadow: 0px 1px 2px 2px #f2f2f2;
        border-radius: 6px;
        padding: 15px;
        p {
            margin-bottom: 5px;
            font-size: 14px;
            &.name {
                font-size: 16px;
                margin-bottom: 0px;
            }
            &.description {
                font-size: 13px;
                margin-bottom: 10px;
                opacity: 0.8;
            }
            &.price {
                float: right;
            }
        }
        span {
            font-size: 13px;
        }
        .tag {
            background: #f2f2f2;
            padding: 5px 10px;
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 5px;
            font-family: "DM Sans";
            word-wrap: break-all; 
            font-weight: 500;
            font-size: 13px;
            line-height: 14px;
            text-align: center;
            color: #096dd9;
            opacity: 0.75;
            border-radius: 10px;
        }
    }
`;

export { LabRequestsDrawerWrapper, LabRequestsDrawerHeader, LabRequestsDrawerBody, LabRequestsItemWrapper }