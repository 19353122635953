import { Button as AntdButton } from "antd"
import { ButtonWrapper } from "./styles";

const Button = (props: any) => {
    const { type, className, ...rest } = props;
    return (
        <ButtonWrapper className={className} type={type}>
            <AntdButton {...rest}>
                {props.children}
            </AntdButton>
        </ButtonWrapper>
    )
}

export default Button;