import styled from 'styled-components';


const DashboardWrapper = styled.div`
`;

const Container = styled.div`
    display: flex;
    z-index: 0;
`;

const MainWrapper = styled.section`
    height: calc(100vh - 57px);
    margin-top: 57px;
    width: calc(100% - 264px);
    margin-left: 270px;
    display: flex;
    flex-direction: row;
    @media (max-width: 1024px) {
        width: calc(100%);
        margin-left: 0px;
    }
`;

export { DashboardWrapper, Container, MainWrapper }