import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Modal, Row, Select as AntdSelect, InputNumber } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addInvestgation,
  fetchAllInvestgations,
  investigationSelector,
  updateInvestgation
} from "../../redux/reducers/investigations";
import Button from "../Button";
import Input from "../Input";
import Select from "../Select";
import { RequestDiagnosisModalWrapper } from "./styles";

const AddServiceModal = ({
  isSelectedForEdit,
  isModalVisible,
  handleCancel
}: any) => {
  const dispatch = useDispatch();
  const {
    investigations: allInvestigations,
    addInvestgationsLoading,
    updateInvestgationsLoading,
    hasCreatedInvestigation,
    hasUpdatedInvestigation
  } = useSelector(investigationSelector);
  const [requestForm] = Form.useForm();

  const pneumaCurrentLab = JSON.parse(
    window.localStorage.getItem("pneumaCurrentLab") || ""
  );
  const { integration } = pneumaCurrentLab || {};

  useEffect(() => {
    if (hasCreatedInvestigation) {
      requestForm.resetFields();
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCreatedInvestigation]);

  useEffect(() => {
    if (hasUpdatedInvestigation) {
      requestForm.resetFields();
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdatedInvestigation]);

  useEffect(() => {
    dispatch(fetchAllInvestgations(integration));
  }, [dispatch, integration]);

  const handleCreateInvestigation = async (values: any) => {
    const _values = { ...values };
    const { turn_around, time_category, investigation, price, sample_bottle } =
      _values;

    const turn_around_time = `${turn_around} ${time_category || "hour(s)"}`;
    const payload = [
      {
        sample_bottle,
        investigation,
        price: parseInt(price) * 100,
        turn_around_time
      }
    ];
    await dispatch(addInvestgation(integration, { investigations: payload }));
  };

  const handleUpdateInvestigation = async (values: any) => {
    const _values = { ...values };
    const lab_reference_id = isSelectedForEdit?.lab_reference_id;
    const { turn_around, time_category, investigation, price, sample_bottle } =
      _values;
    const turn_around_time = `${turn_around} ${time_category}`;
    const payload = {
      sample_bottle,
      investigation,
      price: parseInt(price) * 100,
      turn_around_time
    };
    await dispatch(updateInvestgation(lab_reference_id, integration, payload));
  };

  useEffect(() => {
    if (isSelectedForEdit) {
      const { turn_around_time, id, price, sample_bottle } =
        isSelectedForEdit || {};
      const fields = {
        turn_around: turn_around_time?.split(" ")[0],
        time_category: turn_around_time?.split(" ")[1],
        investigation: id,
        price,
        sample_bottle
      };
      requestForm.setFieldsValue(fields);
    }
  }, [isSelectedForEdit, requestForm]);

  return (
    <Modal
      title={`${isSelectedForEdit ? "Edit" : "Add"} Investigation`}
      okText='Request'
      footer={null}
      className='dashboard-mode-modal'
      visible={isModalVisible}
      onCancel={handleCancel}
    >
      <RequestDiagnosisModalWrapper>
        <Form
          name='basicForm'
          form={requestForm}
          layout='vertical'
          onFinish={
            isSelectedForEdit
              ? handleUpdateInvestigation
              : handleCreateInvestigation
          }
        >
          <Row gutter={24}>
            <Col span={24}>
              <Select
                formItem={{
                  name: "investigation",
                  label: "Investigations",
                  rules: [
                    {
                      required: true,
                      message: "Please select investigation"
                    }
                  ]
                }}
                showSearch={true}
                suffixIcon={<SearchOutlined />}
                optionFilterProp='children'
                placeholder='Type to search for investigation'
                label=''
                mode='normal'
                required
              >
                {allInvestigations.map((option, index) => {
                  return (
                    <AntdSelect.Option key={index} value={option.id}>
                      {option.name}
                    </AntdSelect.Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={12}>
              <Form.Item
                name='price'
                label='Price'
                rules={[{ required: true, message: "Please enter price" }]}
              >
                <InputNumber
                  formatter={(value: any) =>
                    `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value: any) => value!.replace(/₦\s?|(,*)/g, "")}
                  className='price-input'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Input
                formItem={{
                  name: "sample_bottle",
                  label: "Sample Bottle",
                  rules: [
                    {
                      required: true,
                      message: "Please enter sample bottle"
                    }
                  ]
                }}
                placeholder='Enter sample bottle'
                label=''
                mode='normal'
                required
              />
            </Col>

            <Col span={24} style={{ marginTop: "16px" }}>
              <p
                style={{
                  marginBottom: "5px",
                  fontSize: "13px",
                  fontWeight: "500"
                }}
              >
                Turn Around Time
              </p>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col>
              <Form.Item name='turn_around'>
                <InputNumber
                  formatter={(value: any) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value: any) => value!.replace(/₦\s?|(,*)/g, "")}
                  className='turn_around-input'
                  placeholder='Duration'
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Select
                formItem={{
                  name: "time_category",
                  label: ""
                }}
                optionFilterProp='children'
                placeholder='Time'
                label=''
                defaultValue='hour(s)'
                mode='normal'
                required
              >
                {["hour(s)", "day(s)", "week(s)"].map((option, index) => {
                  return (
                    <AntdSelect.Option key={index} value={option}>
                      <span style={{ textDecoration: "capitalize" }}>
                        {option}
                      </span>
                    </AntdSelect.Option>
                  );
                })}
              </Select>
            </Col>
          </Row>

          <Row justify='end' style={{ marginTop: "20px" }}>
            <Button
              type='secondary'
              onClick={handleCancel}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            {isSelectedForEdit ? (
              <Button htmlType='submit' type='primary'>
                {updateInvestgationsLoading
                  ? "Updating..."
                  : `Update Investigation`}
              </Button>
            ) : (
              <Button htmlType='submit' type='primary'>
                {addInvestgationsLoading ? "Adding..." : `Add Investigation`}
              </Button>
            )}
          </Row>
        </Form>
      </RequestDiagnosisModalWrapper>
    </Modal>
  );
};

export default AddServiceModal;
