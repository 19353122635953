import { Dropdown, Menu } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AppIcon from "../../assets/images/icon.svg";
import { logOutProvider } from "../../redux/reducers/auth";
import AvatarImg from "../Avatar";
import { MenuLink, NavbarWrapper } from "./styles";

const Navbar = ({ setOpenedMenu }: any) => {
    const dispatch = useDispatch();
    
    const profile = JSON.parse(localStorage.getItem('pneumaCurrentLab') || '');
    const { name,  } = profile || {};

    const handleLogout = () => {
        dispatch(logOutProvider());
    }

    const menu = (
        <Menu>
          <Menu.Item key="0">
            <Link to="/manage-account">Manage account</Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="1" onClick={handleLogout}>Log out</Menu.Item>
        </Menu>
    );

    return (
        <NavbarWrapper>
            <span style={{display: 'flex', alignItems: 'center'}}>
                <i className="lni lni-menu menu-icon" onClick={() => setOpenedMenu(true)}></i>
                <img src={AppIcon} alt="icon" />
            </span>
            <div className="menu">
                <Dropdown overlay={menu} trigger={['click']}>
                    <MenuLink>
                        <span className="username" style={{marginRight: 10}}>{name}</span>
                        <AvatarImg img="" fallbackText={`${name}`} />
                    </MenuLink>
                </Dropdown>
            </div>
        </NavbarWrapper>
    )
}

export default Navbar;
