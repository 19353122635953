import styled from "styled-components";

const RequestDiagnosisModalWrapper = styled.div`
  padding: 0px !important;
  .mini-cart {
    .cart-item {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      margin-bottom: 10px;
      border-radius: 6px;
      background: #fff;
      border: 1px solid #f2f2f2;
      p {
        font-size: 13px;
        margin-bottom: 0px;
      }
      .main {
        flex: 1;
      }
      .price {
        margin-right: 10px;
        color: green;
      }
      .anticon {
        cursor: pointer;
        svg:hover {
          fill: crimson;
        }
      }
      h3 {
        margin-bottom: 0px;
      }
    }
  }
  .no-display {
    .ant-select-selection-item {
      display: none;
    }
  }

  .ant-col .ant-form-item-label {
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight: 500;
  }

  .price-input,
  .turn_around-input {
    width: 100% !important;
    padding: 6px 8px;
    border: 1px solid #bfcad0;
    border-radius: 8px;
    height: 44px !important;
  }
`;

export { RequestDiagnosisModalWrapper };
