import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http, { httpWithJWT2 } from "../../utils/api";
import { fetchSingleConsult } from "./consults";
import { message } from "antd";
import { fetchProfile } from "./auth";

export interface InvestigationError {
  message: string;
}

export interface InvestigationState {
  investigation: any;
  investigations: Array<any>;
  isInvestgationsLoading: boolean;
  addInvestgationsLoading: boolean;
  updateInvestgationsLoading: boolean;
  hasCreatedInvestigation: boolean;
  hasUpdatedInvestigation: boolean;
  deleteInvestgationsLoading: boolean;
  investigationsError: InvestigationError;
  addInvestgationsError: InvestigationError;
  updateInvestgationsError: InvestigationError;
  deleteInvestgationsError: InvestigationError;
}

export const initialState: InvestigationState = {
  investigation: null,
  investigations: [],
  isInvestgationsLoading: false,
  addInvestgationsLoading: false,
  updateInvestgationsLoading: false,
  hasCreatedInvestigation: false,
  hasUpdatedInvestigation: false,
  deleteInvestgationsLoading: false,
  investigationsError: { message: "" },
  addInvestgationsError: { message: "" },
  updateInvestgationsError: { message: "" },
  deleteInvestgationsError: { message: "" }
};

export const investigationsSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    fetchInvestgationsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isInvestgationsLoading = payload;
    },
    fetchInvestgationsSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.investigations = payload;
    },
    fetchInvestgationsFailed: (
      state,
      { payload }: PayloadAction<InvestigationError>
    ) => {
      state.investigationsError = payload;
    },
    addInvestgationsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.addInvestgationsLoading = payload;
      state.hasCreatedInvestigation = false;
    },
    addInvestgationsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      state.hasCreatedInvestigation = true;
    },
    addInvestgationsFailed: (
      state,
      { payload }: PayloadAction<InvestigationError>
    ) => {
      state.addInvestgationsError = payload;
      state.hasCreatedInvestigation = false;
    },
    updateInvestgationsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.updateInvestgationsLoading = payload;
      state.hasUpdatedInvestigation = false;
    },
    updateInvestgationsSuccess: (
      state,
      { payload }: PayloadAction<Boolean>
    ) => {
      state.hasUpdatedInvestigation = true;
    },
    updateInvestgationsFailed: (
      state,
      { payload }: PayloadAction<InvestigationError>
    ) => {
      state.updateInvestgationsError = payload;
      state.hasUpdatedInvestigation = false;
    },
    deleteInvestgationsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.deleteInvestgationsLoading = payload;
    },
    deleteInvestgationsSuccess: (
      state,
      { payload }: PayloadAction<Boolean>
    ) => {
      // state.investigations = payload;
    },
    deleteInvestgationsFailed: (
      state,
      { payload }: PayloadAction<InvestigationError>
    ) => {
      state.deleteInvestgationsError = payload;
    }
  }
});

export const {
  fetchInvestgationsLoading,
  fetchInvestgationsSuccess,
  fetchInvestgationsFailed,
  addInvestgationsLoading,
  addInvestgationsSuccess,
  addInvestgationsFailed,
  updateInvestgationsLoading,
  updateInvestgationsSuccess,
  updateInvestgationsFailed,
  deleteInvestgationsLoading,
  deleteInvestgationsSuccess,
  deleteInvestgationsFailed
} = investigationsSlice.actions;
export const investigationSelector = (state: {
  investigations: InvestigationState;
}) => state.investigations;
export default investigationsSlice.reducer;

/** Actions */

export const fetchAllInvestgations =
  (integrationId: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchInvestgationsLoading(true));
    await httpWithJWT2
      .get(`/investigations`)
      .then((res) => {
        const investigations = res?.data?.data;
        dispatch(fetchInvestgationsSuccess(investigations));
      })
      .catch((err) => {
        const message = { message: err?.response?.data?.message };
        dispatch(fetchInvestgationsFailed(message));
      });
    dispatch(fetchInvestgationsLoading(false));
  };

export const addInvestgation =
  (integrationId: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(addInvestgationsLoading(true));
    await http
      .post(`/laboratories/investigations`, payload)
      .then((res) => {
        const investigation = res?.data?.data;
        dispatch(addInvestgationsSuccess(investigation));
        message.success("Investigations Added Successfully");
        dispatch(fetchProfile());
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.error || "An error occurred"
        };
        dispatch(addInvestgationsFailed(_message));
        message.error(err?.response?.data?.error || "An error occurred");
      });
    dispatch(addInvestgationsLoading(false));
  };

export const updateInvestgation =
  (investigationId: any, integrationId: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(addInvestgationsLoading(true));
    await http
      .patch(`/laboratories/investigations/${investigationId}`, payload)
      .then((res) => {
        const investigation = res?.data?.data;
        dispatch(addInvestgationsSuccess(investigation));
        message.success("Investigations Updated Successfully");
        dispatch(fetchProfile());
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(addInvestgationsFailed(_message));
        message.error(err?.response?.data?.message || "An error occurred");
      });
    dispatch(addInvestgationsLoading(false));
  };

export const deleteInvestgation =
  (investigationId: any, integrationId: any): AppThunk =>
  async (dispatch) => {
    dispatch(deleteInvestgationsLoading(true));
    await http
      .delete(`/laboratories/investigations/${investigationId}`)
      .then((res) => {
        const investigation = res?.data?.data;
        dispatch(deleteInvestgationsSuccess(investigation));
        message.success("Investigations Deleted Successfully");
        dispatch(fetchProfile());
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(deleteInvestgationsFailed(_message));
        message.error(err?.response?.data?.message || "An error occurred");
      });
    dispatch(deleteInvestgationsLoading(false));
  };
