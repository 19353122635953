import styled, { css } from 'styled-components';


interface ButtonProps {
    type: string
}

const styles = (key: string) => {
    return (key === "primary")
        ? css`
            background: #0f9af0;
            color: #fff;
            font-size: 13px;
            border: 1px solid #0f9af0;
            &:hover:not(:disabled) {
                background: #fff;
                color: #0f9af0;
                border: 1px solid #0f9af0;
            }
        `
        : (key === "secondary")
            ? css`
                background: #fff;
                text-align: left;
                color: #0f9af0;
                border: 1px solid #0f9af0;
                &:hover:not(:disabled) {
                    background: #0f9af0;
                    color: #fff;
                    border: 1px solid #fff;
                }
                &.ant-btn[disabled]:hover, &:hover(:disabled) {
                    background: #fff;
                    color: #0f9af0;
                    border: 1px solid #0f9af0;
                }
            `
            : null
}

const ButtonWrapper = styled.div<ButtonProps>`
    display: inline-block;
    button.ant-btn {
        height: 35px;
        border-radius: 4px;
        font-family: "DM Sans";
        font-weight: 400;
        border: none;
        line-height: 9px;
        text-align: center;
        transition: all 0.01s ease-in;
        ${(props) => props.type ? styles(props.type) : ''};
        &:disabled {
            opacity: 0.5;
        }
    }
    ${(props) => props.type === "primary" && `
        .ant-btn[disabled]:hover {
            background: #0f9af0 !important;
            color: #fff;
            border: 1px solid #0f9af0;
        }
    `}
`;

export { ButtonWrapper }