import ClaimsTable from "./containers/ClaimsTable";
import { ClaimsWrapper, ContentWrapper } from "./styles";

const Claims = ({ dashboardMode }: any) => {
    return (
        <ClaimsWrapper>
            <ContentWrapper>
                <ClaimsTable dashboardMode={dashboardMode} />
            </ContentWrapper>
        </ClaimsWrapper>
    )
}

export default Claims;