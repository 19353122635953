import styled from "styled-components";

interface StatsCardProps {
  active: boolean;
  isCurrency?: boolean;
}

const DashboardWrapper = styled.div`
  width: 100%;
  h3 {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #4e667e;
  }
`;

const ContentWrapper = styled.div`
  background: #fff;
  min-height: calc(100vh - 60px);
  padding-bottom: 100px;
  padding: 24px 24px 20px;
  position: relative;
  .RHS {
    position: fixed;
    width: inherit;
    max-width: inherit;
    .ant-card {
      min-height: calc(100vh - 57px);
      width: 360px;
      right: 0px;
    }
  }
  .encounters-pane {
      h2 {
        margin-bottom: 0px;
        font: normal normal normal 18px/24px DM Sans;
        color: #002e46;
      }
  }
`;

const StatsCardWrapper = styled.div<StatsCardProps>`
  margin-bottom: 20px;
  .ant-card {
    border-radius: 8px;
    box-shadow: 0px 0px 3px #0f9af01a;
    ${(props) => props.active && `border: 1px solid #0f9af0BF;`}
    .ant-card-body {
        padding: 18px 24px;
    }
  }
  .icon {
    width: 35px;
    fill: #1890ff;
  }
  .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .flex {
        display: flex;
        justify-content: space-between;
      }
  }
  p.title {
    text-align: left;
    text-transform: capitalize;
    font: normal normal medium 13px/14px DM Sans;
    line-height: 18px;
    letter-spacing: 0px;
    color: #002e46;
    word-break: break-all;
    white-space: pre-line;
    opacity: 0.8;
    margin-bottom: 4px;
    @media(max-width: 500px) {
        font-size: 12px;
    }
  }
  h2 {
    text-align: left;
    font: normal normal normal 28px/34px DM Sans;
    letter-spacing: 0px;
    font-weight: 600;
    color: ${props => props.isCurrency ? "#002e46" : "#002e46"};
    opacity: 0.8;
    margin-bottom: 0px;
  }
  span.link {
    /* text-transform: capitalize; */
    text-align: right;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 4px;
    width: auto;
    white-space: pre;
    @media(max-width: 500px) {
        white-space: normal;
    }
    a {
        text-align: right;
        font-weight: 600;
        background: #0f9af01a;
        padding: 10px 15px;
        border-radius: 8px;
        border: 1px solid #fff;
        @media(max-width: 500px) {
            background: transparent;
            border: none;
            padding: 0px;
        }
        &:hover {
            background: #fff;
            border: 1px solid #0f9af01a;
        }
        i {
            margin-left: 5px;
        }
        .lni {
            @media(max-width: 500px) {
                display: none;
            }
        }
    }
  }
`;

const AddTextWrapper = styled.p`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  margin-top: 5px;
  cursor: pointer;
  color: #0f9af0;
  img {
    margin-right: 7px;
  }
`;

const OverviewIntroCardWrapper = styled.div`
  background: #0f9af0;
  width: 100%;
  box-shadow: 0px 0px 3px #0f9af01a;
  border-radius: 8px;
  .inner {
    padding: 50px 40px;
  }
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  h3 {
    font-family: "DM Sans";
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: #fff;
    margin-bottom: 4px;
  }
  p {
    margin-bottom: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    opacity: 1;
    @media(max-width: 767px) {
        margin-bottom: 20px;
    }
  }
  .circles {
    background: radial-gradient(
      214px 214px at 60% 90%,
      #0ff0c052 50%,
      rgba(238, 152, 71, 0) 0
    );
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

const WalletCardWrapper = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 3px #0f9af01a;
  border-radius: 8px;
  padding: 18px 24px 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    text-align: left;
    font: normal normal normal 28px/34px DM Sans;
    letter-spacing: 0px;
    font-weight: 600;
    color: #002e46;
    opacity: 0.8;
    margin-bottom: 0px;
    small {
        font-size: 18px;
    }
  }
  p {
    margin-bottom: 0px;
    text-transform: capitalize;
  }
  p.link {
    text-transform: capitalize;
    text-align: right;
    font-size: 12px;
    margin-bottom: 4px;
    margin-top: 15px;
    a {
        text-align: right;
        font-weight: 600;
        background: #0f9af01a;
        padding: 10px 15px;
        border-radius: 8px;
        border: 1px solid #fff;
        &:hover {
            background: #fff;
            border: 1px solid #0f9af01a;
        }
        i {
            margin-left: 5px;
        }
    }
  }
`;

export {
  DashboardWrapper,
  ContentWrapper,
  StatsCardWrapper,
  AddTextWrapper,
  OverviewIntroCardWrapper,
  WalletCardWrapper,
};
