import { useState } from "react";
import { Col, Row, Form, Checkbox } from "antd";
import { useHistory } from "react-router-dom";
import AppIcon from "../../../assets/images/icon.svg";
import Button from "../../../component/Button";
import Input from "../../../component/Input";
import RegisterSvg from "../../../assets/icons/register-icon.svg";
import { InfoCircleOutlined } from "@ant-design/icons";
import ValidCheckSvg from "../../../assets/icons/valid-icon.svg";
import InvalidCheckSvg from "../../../assets/icons/invalid-icon.svg";
import { authSelector, registerLaboratory } from "../../../redux/reducers/auth";
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash";
import { ErrorMsg } from "../../../layouts/OnboardingLayout/styles";

const Register = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
    const [primaryLoginChoice, setPrimaryLoginChoice] = useState("email");
    const [gender, setGender] = useState("");
    const [password, setPassword] = useState("");
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [payload, setPayload] = useState<any>({});

    const { isLoading, error } = useSelector(authSelector);

    const steps = [
        {
            key: "name",
            component: (props: any) => props.isVisible && (
                <Col span={24}>
                    <Input
                        formItem={{
                            label: "What is your laboratory name?",
                            name: "name",
                            rules: [
                                { required: true, message: 'Please input your laboratory name' }
                            ]
                        }}
                        placeholder="Laboratory Name"
                        type="text"
                        marginBottom={0}
                        label="What is your laboratory name?"
                    />
                </Col>
            )
        },
        {
            key: "primaryLoginChoice",
            component: (props: any) => props.isVisible && (
                <Col span={24}>
                    <Input
                        formItem={{
                            label: "What is your email address?",
                            name: "email",
                            rules: [
                                { required: true, message: 'Please input your email' }
                            ],
                            tooltip: { title: 'This helps us provide relevant information for you.', icon: <InfoCircleOutlined /> }
                        }}
                        placeholder="name@mail.com"
                        type="email"
                        marginBottom={0}
                        label="What is your email address?"
                    />
                </Col>
            )
        },
        {
            key: "phoneNumber",
            component: (props: any) => props.isVisible && (
                <Col span={24}>
                    <Input
                        formItem={{
                            label: "What is your phone number?",
                            name: "phone",
                            rules: [
                                { required: true, message: 'Please input your phone number' }
                            ],
                            tooltip: { title: 'This helps us provide relevant information for you.', icon: <InfoCircleOutlined /> }
                        }}
                        className="phone-input"
                        placeholder="08012345678"
                        type="phone"
                        marginBottom={0}
                        label="What is your phone number?"
                    />
                </Col>
            )
        },
        {
            key: "address",
            component: (props: any) => props.isVisible && (
                <>
                    <Row gutter={20} style={{ width: '100%' }}>
                        <Col span={16}>
                            <Input
                                formItem={{
                                    label: "Your Street Address?",
                                    name: "street_line_one",
                                    rules: [
                                        { required: true, message: 'Please input your Street Address' }
                                    ]
                                }}
                                placeholder="Street Address"
                                type="text"
                                label="What is your Street Address?"
                            />
                        </Col>
                        <Col span={8}>
                            <Input
                                formItem={{
                                    label: "Postal Code?",
                                    name: "post_code",
                                    rules: [
                                        { required: true, message: 'Please input your Postal Code' }
                                    ]
                                }}
                                placeholder=""
                                type="text"
                                label="What is your Postal Code?"
                            />
                        </Col>
                    </Row>
                    <Row gutter={20} style={{ width: '100%' }}>
                        <Col span={8}>
                            <Input
                                formItem={{
                                    label: "Your City?",
                                    name: "city",
                                    rules: [
                                        { required: true, message: 'Please input your City' }
                                    ]
                                }}
                                placeholder="City"
                                type="text"
                                marginBottom={0}
                                label="What is your City?"
                            />
                        </Col>
                        <Col span={8}>
                            <Input
                                formItem={{
                                    label: "Your State?",
                                    name: "state",
                                    rules: [
                                        { required: true, message: 'Please input your State' }
                                    ]
                                }}
                                placeholder="State"
                                type="text"
                                marginBottom={0}
                                label="What is your State?"
                            />
                        </Col>
                        <Col span={8}>
                            <Input
                                formItem={{
                                    label: "Your Country?",
                                    name: "country",
                                    rules: [
                                        { required: true, message: 'Please input your Country' }
                                    ]
                                }}
                                placeholder="Country"
                                type="text"
                                marginBottom={0}
                                label="What is your Country?"
                            />
                        </Col>
                    </Row>
                </>
            )
        },
        {
            key: "password",
            component: (props: any) => {
                const content = (
                    <div>
                        <p className="title">Password should contain</p>
                        <p><img src={/.{8,}/.test(props.password) ? ValidCheckSvg : InvalidCheckSvg} alt="valid/not icon" /> 8 or more characters </p>
                        <p><img src={/(?=.*?[a-z])/.test(props.password) ? ValidCheckSvg : InvalidCheckSvg} alt="valid/not icon" /> At least one lower case letter </p>
                        <p><img src={/(?=.*?[A-Z])/.test(props.password) ? ValidCheckSvg : InvalidCheckSvg} alt="valid/not icon" /> At least one capital letter </p>
                        <p><img src={/(?=.*?[0-9])/.test(props.password) ? ValidCheckSvg : InvalidCheckSvg} alt="valid/not icon" /> At least one number </p>
                        <p><img src={/(?=.*?[#?!@$%^&*-])/.test(props.password) ? ValidCheckSvg : InvalidCheckSvg} alt="valid/not icon" /> At least one symbol</p>
                    </div>
                );
                return props.isVisible && (
                    <Col span={24}>
                        <Input
                            formItem={{
                                label: "Create a password",
                                name: "password",
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [
                                    { required: true, message: 'Please input your password' },
                                    {
                                        pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                        message: 'Password must contain atleast one uppercase, one lowercase, a number, and a special character'
                                    }
                                ]
                            }}
                            hasHintPopoverContent={props.passwordFocused}
                            hintPopoverContent={content}
                            onChange={(e: any) => props.setPassword(e.target.value)}
                            onFocus={() => props.setPasswordFocused(true)}
                            onBlur={() => props.setPasswordFocused(false)}
                            placeholder="password"
                            type="password"
                            label="Create a password"
                        />
                        <Input
                            formItem={{
                                label: "Confirm password",
                                name: "confirm_password",
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [
                                    { required: true, message: 'Please confirm your password' },
                                    {
                                        pattern: props.password,
                                        message: 'Password does not match'
                                    }
                                ]
                            }}
                            placeholder="password"
                            type="password"
                            marginBottom={10}
                            label="Confirm password"
                        />
                        <span className="terms-conditions-text">
                            <Checkbox />
                            Accept terms and condtions
                        </span>
                        <Row>
                            <Col span={24}>
                                <ErrorMsg className="error-msg">{error?.message}</ErrorMsg>
                            </Col>
                        </Row>
                    </Col>
                )
            }
        },
    ]

    const parsePayload = (password: string) => {
        const { street_line_one, city, state, country, post_code, } = payload || {};

        setPayload({ ...payload, password });
        const newPayload: any = _.pick(payload, ['name', 'email', 'phone']);
        newPayload.password = password;
        newPayload.imaging = true;
        newPayload.address = {
            street_line_one,
            city,
            state,
            country,
            post_code
        }
        return newPayload;
    }

    const handleRegister = async (values: any) => {
        const _payload = { ...payload };
        setPayload({ ..._payload, ...values });
        if (currentIndex !== steps.length - 1) {
            setCurrentIndex(currentIndex + 1)
        } else {
            const finalPayload = parsePayload(values.password);
            await dispatch(registerLaboratory(finalPayload));
        }
    }

    // return isRegistrationSuccessful ? <EmailLinkSent setIsRegistrationSuccessful={setIsRegistrationSuccessful} /> : (
    return (
        <>
            <Col xs={0} md={12}>
                <div className="left-pane">
                    <img className="app-logo" src={AppIcon} alt="logo" />
                    <div className="align-center">
                        <img src={RegisterSvg} alt="icon" />
                        <h3>Create an account</h3>
                        <p>Enter your details to create an account</p>
                    </div>
                </div>
            </Col>
            <Col xs={24} md={12}>
                <div className="right-pane">
                    <span className="close-icon" onClick={() => history.goBack()}>
                        <i className="lni lni-arrow-left" /> Back
                    </span>
                    <Form
                        name="basic"
                        layout="vertical"
                        requiredMark={false}
                        onFinish={handleRegister}
                    >
                        <Row gutter={24} align="middle">
                            {steps.map((step, index) => {
                                const isVisible = index === currentIndex;
                                const { component, key } = step;
                                const componentProps = key === "primaryLoginChoice"
                                    ? { isVisible, primaryLoginChoice, setPrimaryLoginChoice }
                                    : key === "gender"
                                        ? { isVisible, gender, setGender }
                                        : key === "password"
                                            ? { isVisible, password, setPassword, passwordFocused, setPasswordFocused }
                                            : { isVisible }
                                return component(componentProps)
                            })}
                            <Col span={24} className={`align-center ${currentIndex === 0 ? 'justify-end' : 'justify-between'}`} style={{marginTop: 20}}>
                                {
                                    (currentIndex !== 0)
                                    && <Button onClick={() => setCurrentIndex(currentIndex - 1)} type="secondary" className="auth-button prev-button">Previous</Button>
                                }
                                {
                                    (currentIndex === steps.length - 1)
                                        ? <Button htmlType="submit" type="primary" className="auth-button" style={{marginRight: 10}}>{isLoading ? 'Creating Account...' : 'Create Account'}</Button>
                                        : <Button htmlType="submit" type="primary" className="auth-button next-button">Next</Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
        </>
    )
}

export default Register;