import { InvestigationWrapper, ContentWrapper } from "./styles";
import InvestigationTable from "./containers/InvestigationTable";

const Investigations = () => {
    return (
        <InvestigationWrapper>
            <ContentWrapper>
                <InvestigationTable />
            </ContentWrapper>
        </InvestigationWrapper>
    )
}

export default Investigations;