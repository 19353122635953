import { SearchOutlined } from "@ant-design/icons";
import { Table, Row, Col, Select as AntdSelect, Dropdown, Menu } from "antd";
import Card from "../../../../component/Card";
import { LabRequestsTableWrapper, LabRequestsTableBody } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import Button from "../../../../component/Button";
import LabRequestsDrawer from "../LabRequestsDrawer";
import {
  fetchAllLabRequests,
  labRequestSelector,
  updateLaboratoryRequestStatus,
} from "../../../../redux/reducers/labRequests";
import Search from "../../../../component/Search";
import Select from "../../../../component/Select";
import DropdownIcon from "../../../../assets/icons/dropdown.svg";
import Input from "../../../../component/Input";
const { Option } = AntdSelect;

// enum Statuses {
//   PENDING = 'pending',
//   COLLECTED = 'sample_collected',
//   DELIVERED = 'delivered_at_lab',
//   PROCESSING = 'processing',
//   COMPLETED = 'completed',
//   CANCELLED = 'cancelled',
//   CONTAMINATED = 'contaminated_sample'
// }

const Statuses: any = {
  Pending: 'pending',
  Collected: 'sample_collected',
  Delivered: 'delivered_at_lab',
  Processing: 'processing',
  Completed: 'completed',
  Cancelled: 'cancelled',
  Contaminated: 'contaminated_sample'
}

interface LabRequestsTableProps {
  count?: number;
  hasPagination?: boolean;
  marginTop?: number;
  dashboardMode?: string;
  status?: string;
}

const LabRequestsTable = ({ count, dashboardMode, status }: LabRequestsTableProps) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    patient: "",
    partner: "",
  })
  const [keyword, setKeyword] = useState("");
  const { labRequests, isLabRequestsLoading, isUpdatingLabRequestStatus } = useSelector(labRequestSelector);
  const [labRequestsState, setLabRequestsState] = useState<any[]>([]);
  const [selectedLabRequest, setSelectedLabRequests] = useState(null);
  const [isLabRequestsDrawerOpen, setIsLabRequestsDrawerOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchAllLabRequests());
  }, [dispatch]);

  useEffect(() => {
    setLabRequestsState(labRequests.filter(item => status ? item.status === status : item));
  }, [labRequests, status]);

  useEffect(() => {
    const _labRequests = [...labRequests];
    const {partner, patient} = filter;
    const val = _labRequests
      .filter(({ user, integration, status: itemStatus, createdAt }: any) => {
        const fullName = `${user.first_name} ${user.last_name}`;
        const businessName = `${integration?.business_name}`;
        return status ? itemStatus === status : itemStatus
          && businessName.toLowerCase().includes(partner.toLowerCase())
          && fullName.toLowerCase().includes(patient.toLowerCase())
      })
      setLabRequestsState(val);
  }, [filter, labRequests, status]);

  const handleOpenDrawer = (item: any) => {
    setSelectedLabRequests(item);
    setIsLabRequestsDrawerOpen(true);
  };

  const statusMenu = (id: any, status: string) => (
    <Menu
      className="status-menu"
      onClick={(e: any) => {
        handleStatusUpdate(id, e.key);
      }}
    >
      {Object.keys(Statuses).map((key: string) => (
        <Menu.Item
          key={Statuses[key]}
          className={status === Statuses[key] ? "active" : ""}
        >
          <span style={{ textTransform: "capitalize" }}>{key}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleStatusUpdate = (id: any, status: string) => {
    const payload = { status }
    dispatch(updateLaboratoryRequestStatus(id, payload))
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return <p>{id}</p>;
      },
    },
    {
      title: "Patient's Name",
      dataIndex: "name",
      key: "first_name",
      width: 200,
      sorter: (a: any, b: any) =>
        a?.user?.first_name.toLowerCase() < b?.user?.first_name.toLowerCase()
          ? -1
          : a?.user?.first_name.toLowerCase() >
            b?.user?.first_name.toLowerCase()
          ? 1
          : 0,
      render: (_: string, record: any) => {
        const { first_name, last_name } = record?.user || {};
        return (
          <div className="flex align-center" style={{ alignItems: "center" }}>
            <div>
              <p style={{ margin: 0 }}>
                {first_name} {last_name}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Partner",
      dataIndex: "partner",
      key: "partner",
      render: (_: any, record: any) => {
        const { business_name } = record?.integration || {};
        return <p>{business_name}</p>;
      },
    },
    {
      title: "Price",
      dataIndex: "total_price",
      key: "total_price",
      render: (total_price: any) => {
        return <p>NGN {(total_price ? (total_price/100) : 0).toLocaleString()}</p>;
      },
    },
    {
      title: "Created on",
      dataIndex: "schedule",
      key: "schedule",
      render: (_: Date, record: any) => {
        return (
          <>
            <p>{moment(record.created_at).format("LL")}</p>
            <span className="subtext">
              {moment(record.created_at).format("hh:mm a")}
            </span>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // align: "center" as "center",
      // width: "180px",
      render: (status: string, record: any) => {
        const { id } = record;
        return (
          <div style={{ textAlign: "left" }}>
            <span
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Dropdown
              overlay={statusMenu(id, status)}
              trigger={["click"]}
              placement="bottomLeft"
              className="status-changer"
              >
              <a
                href="#!"
                className={`ant-dropdown-link ${status} text-capitalize`}
                style={{ textTransform: "capitalize" }}
              >
                <b style={{ marginRight: 5 }}>{status}</b>{" "}
                <img
                  width="10px"
                  className="ml-1"
                  alt="icon"
                  src={DropdownIcon}
                />
              </a>
            </Dropdown>
          </span>
            {/* <Select
              marginBottom={0}
              defaultValue={status}
              placeholder="Status"
              mode="normal"
              disabled={isUpdatingLabRequestStatus}
              onChange={(status: string) => handleStatusUpdate(id, status)}
            >
              {Object.keys(Statuses).map((key: string) => {
                const value = Statuses[key];
                return <Option value={value}>{key}</Option>;
              })}
            </Select> */}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center" as "center",
      render: (_: string, record: any) => {
        return (
          <Button type="secondary" onClick={() => handleOpenDrawer(record)}>
            View details
          </Button>
        );
      },
    },
  ];

  const handlePatientSearch = (e: any) => {
    const keyword = e.target.value;
    setFilter({...filter, patient: keyword})
  };

  const handlePartnerSearch = (e: any) => {
    const keyword = e.target.value;
    setFilter({...filter, partner: keyword})
  };

  const totalCount =
    labRequestsState.length === labRequests.length
      ? `${labRequestsState.length}`
      : `${labRequestsState.length}/${labRequests.length}`;

  const dataSource = count
    ? labRequestsState.slice(0, count)
    : labRequestsState;
  return (
    <>
      {!count && (
        <Row justify="space-between" align="middle">
          <Col xs={24} md={12}>
            <h3>
              {count ? "Pending Requests" : "Service Requests"}{" "}
              <small>({totalCount})</small>
            </h3>
          </Col>
          <Col xs={24} md={12}>
            <div style={{ display: "flex" }}>
            <div className="partner-select">
              <Input
                formItem={{
                  name: "partner",
                  rules: [
                    {
                      required: true,
                      message: "Please select partner",
                    },
                  ],
                }}
                showSearch={true}
                suffixIcon={<SearchOutlined />}
                onChange={handlePartnerSearch}
                marginBottom={0}
                style={{height: '40px',}}
                optionFilterProp="children"
                placeholder="Search Partner"
                label=""
                mode="normal"
                required
              />
            </div>
              <div style={{ flexGrow: 1, marginLeft: '10px', }}>
                <Search
                  placeholder="Search by user's name"
                  onChange={handlePatientSearch}
                />
              </div>
            </div>
          </Col>
          <Col xs={24} md={4}>
            
          </Col>
        </Row>
      )}
      <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
        <LabRequestsTableWrapper>
          <LabRequestsTableBody>
            <Table
              dataSource={labRequestsState}
              columns={columns}
              scroll={
                !isLabRequestsLoading && dataSource.length > 0 ? { x: 700 } : {}
              }
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              loading={isLabRequestsLoading}
              pagination={{ position: ["bottomRight"], showLessItems: true }}
            />
          </LabRequestsTableBody>
        </LabRequestsTableWrapper>
        <LabRequestsDrawer
          isVisible={isLabRequestsDrawerOpen}
          selectedLabRequest={selectedLabRequest}
          onClose={() => setIsLabRequestsDrawerOpen(false)}
        />
      </Card>
    </>
  );
};

export default LabRequestsTable;
