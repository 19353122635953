import { Select as AntdSelect, Form } from "antd"
import { SelectWrapper } from "./styles";

const Select = (props: any) => {
    const { formItem, marginBottom, multiple, mode, ...rest } = props;
    return (
        <SelectWrapper marginBottom={marginBottom} mode={mode} multiple={multiple}>
            <Form.Item {...formItem}>
                <AntdSelect {...rest} mode={multiple ? "multiple" : ""}>
                    {rest.children}
                </AntdSelect>
            </Form.Item>
        </SelectWrapper>
    )
}

export default Select;