import LabRequestsTable from "./containers/LabRequestsTable";
import { LabRequestsWrapper, ContentWrapper } from "./styles";

const LabRequests = ({ dashboardMode }: any) => {
    return (
        <LabRequestsWrapper>
            <ContentWrapper>
                <LabRequestsTable dashboardMode={dashboardMode} />
            </ContentWrapper>
        </LabRequestsWrapper>
    )
}

export default LabRequests;