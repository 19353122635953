import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http, { httpWithJWT } from "../../utils/api";
import { message as AntdMessage } from "antd";
import getAllAPIKeys from "../../utils/getAllAPIKeys";

export interface AuthError {
  message: string;
}

export interface AuthState {
  isAuth: boolean;
  currentUser: any;
  integrationDocuments: [];
  apiKeys: any[];
  isLoading: boolean;
  isProfileLoading: boolean;
  updateProfileLoading: boolean;
  isIntegrationDocumentsLoading: boolean;
  updateIntegrationDocumentsLoading: boolean;
  isAccountActivationLoading: boolean;
  isAPIKeysLoading: boolean;
  isActivatingAccount: boolean;
  isDeactivatingAccount: boolean;
  error: AuthError;
  profileError: AuthError;
  updateProfileError: AuthError;
  apiKeysError: AuthError;
  integrationDocumentsError: AuthError;
  updateIntegrationDocumentsError: AuthError;
  isAccountActivationError: AuthError;
  isActivatingAccountError: AuthError;
  isDeactivatingAccountError: AuthError;
}

export interface CurrentUser {
  id: string;
  display_name: string;
  email: string;
  photo_url: string;
}
export const initialState: AuthState = {
  isAuth: false,
  isLoading: false,
  currentUser: null,
  integrationDocuments: [],
  apiKeys: [],
  isProfileLoading: false,
  updateProfileLoading: false,
  isIntegrationDocumentsLoading: false,
  updateIntegrationDocumentsLoading: false,
  isAccountActivationLoading: false,
  isAPIKeysLoading: false,
  isActivatingAccount: false,
  isDeactivatingAccount: false,
  error: { message: "" },
  profileError: { message: "" },
  updateProfileError: { message: "" },
  apiKeysError: { message: "" },
  integrationDocumentsError: { message: "" },
  updateIntegrationDocumentsError: { message: "" },
  isAccountActivationError: { message: "" },
  isActivatingAccountError: { message: "" },
  isDeactivatingAccountError: { message: "" }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setAuthSuccess: (state, payload: PayloadAction<CurrentUser>) => {
      state.currentUser = payload;
      state.isAuth = true;
    },
    setLogOut: (state) => {
      state.isAuth = false;
      state.currentUser = undefined;
    },
    setAuthFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.error = payload;
      state.isAuth = false;
    },
    fetchProfileLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isProfileLoading = payload;
    },
    fetchProfileSuccess: (state, { payload }: PayloadAction<any>) => {
      state.currentUser = payload;
    },
    fetchProfileFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.profileError = payload;
    },
    updateProfileLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.updateProfileLoading = payload;
    },
    updateProfileSuccess: (state, { payload }: PayloadAction<any>) => {
      state.currentUser = payload;
    },
    updateProfileFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.updateProfileError = payload;
    },
    updateIntegrationDocumentsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.updateIntegrationDocumentsLoading = payload;
    },
    updateIntegrationDocumentsSuccess: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      // state.integrationDocuments = payload;
    },
    updateIntegrationDocumentsFailed: (
      state,
      { payload }: PayloadAction<AuthError>
    ) => {
      state.updateIntegrationDocumentsError = payload;
    },
    fetchIntegrationDocumentsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isIntegrationDocumentsLoading = payload;
    },
    fetchIntegrationDocumentsSuccess: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.integrationDocuments = payload;
    },
    fetchIntegrationDocumentsFailed: (
      state,
      { payload }: PayloadAction<AuthError>
    ) => {
      state.integrationDocumentsError = payload;
    },
    fetchAPIKeysLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isAPIKeysLoading = payload;
    },
    fetchAPIKeysSuccess: (state, { payload }: PayloadAction<any>) => {
      state.apiKeys = payload;
    },
    fetchAPIKeysFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.apiKeysError = payload;
    },
    requestAccountActivationLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAccountActivationLoading = payload;
    },
    requestAccountActivationSuccess: (
      state,
      { payload }: PayloadAction<any>
    ) => {},
    requestAccountActivationFailed: (
      state,
      { payload }: PayloadAction<AuthError>
    ) => {
      state.isAccountActivationError = payload;
    },
    activatingAccountLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isActivatingAccount = payload;
    },
    activatingAccountSuccess: (state, { payload }: PayloadAction<any>) => {},
    activatingAccountFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.isActivatingAccountError = payload;
    },
    deactivatingAccountLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeactivatingAccount = payload;
    },
    deactivatingAccountSuccess: (state, { payload }: PayloadAction<any>) => {},
    deactivatingAccountFailed: (
      state,
      { payload }: PayloadAction<AuthError>
    ) => {
      state.isDeactivatingAccountError = payload;
    }
  }
});

export const {
  setAuthSuccess,
  setLogOut,
  setLoading,
  setAuthFailed,
  fetchProfileLoading,
  fetchProfileSuccess,
  fetchProfileFailed,
  updateProfileLoading,
  updateProfileSuccess,
  updateProfileFailed,
  fetchAPIKeysLoading,
  fetchAPIKeysSuccess,
  fetchAPIKeysFailed,
  updateIntegrationDocumentsLoading,
  updateIntegrationDocumentsSuccess,
  updateIntegrationDocumentsFailed,
  fetchIntegrationDocumentsLoading,
  fetchIntegrationDocumentsSuccess,
  fetchIntegrationDocumentsFailed,
  requestAccountActivationLoading,
  requestAccountActivationSuccess,
  requestAccountActivationFailed,
  activatingAccountFailed,
  activatingAccountSuccess,
  activatingAccountLoading,
  deactivatingAccountFailed,
  deactivatingAccountLoading,
  deactivatingAccountSuccess
} = authSlice.actions;
export const authSelector = (state: { auth: AuthState }) => state.auth;
export default authSlice.reducer;

/** Actions */

export const loginProvider =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    await http
      .post("/laboratories/login", payload)
      .then(async (res) => {
        const { token: accessToken, lab } = res?.data?.data || {};
        dispatch(setAuthSuccess(lab));
        if (accessToken) {
          await http
            .post(
              `/laboratories/integrations/test_keys/${lab.integration}`,
              { password: payload?.password },
              {
                headers: { Authorization: `Bearer ${accessToken}` }
              }
            )
            .then((keysRes) => {
              const accessKey = keysRes?.data?.data;
              const integrationMode =
                window.localStorage.getItem("integrationMode");
              const dashboardMode = window.localStorage.getItem(
                "pneumaDashboardMode"
              );

              let selectedKey;
              if (!integrationMode) {
                const selectedMode = "test";
                selectedKey = accessKey.key;
                window.localStorage.setItem("integrationMode", selectedMode);
                window.localStorage.setItem("accessKey", selectedKey);
              } else {
                selectedKey = accessKey.key;
                window.localStorage.setItem("accessKey", selectedKey);
              }
              window.localStorage.setItem(
                "pneumaCurrentLab",
                JSON.stringify(lab)
              );
              if (!dashboardMode) {
                window.localStorage.setItem(
                  "pneumaDashboardMode",
                  "integration"
                );
              }
              window.localStorage.setItem("accessToken", accessToken);
              window.open("/overview", "_self");
            })
            .catch((err) => {
              const message = {
                message:
                  err?.response?.data?.message || err?.response?.data?.error
              };
              dispatch(setAuthFailed(message));
            });
        }
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(setAuthFailed(message));
      });
    dispatch(setLoading(false));
  };

export const registerLaboratory =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    await http
      .post("/laboratories/register", payload)
      .then((res) => {
        AntdMessage.success("Registration Successful!");
        window.open("/login", "_self");
      })
      .catch((err) => {
        console.log("err..", err?.response?.data);
        const message = { message: err?.response?.data?.message };
        dispatch(setAuthFailed(message));
      });
    dispatch(setLoading(false));
  };

export const getAPIKeys =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchAPIKeysLoading(true));
    const accessToken = window.localStorage.getItem("accessToken");
    const currentUser = JSON.parse(
      window.localStorage.getItem("pneumaCurrentLab") || ""
    );
    if (currentUser) {
      await http
        .post(
          `/integration/${currentUser?.integration}/keys`,
          { password: payload?.password },
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
        .then((keysRes) => {
          const keys = keysRes?.data?.data;
          dispatch(fetchAPIKeysSuccess(keys));
        })
        .catch((err) => {
          const message = {
            message: err?.response?.data?.message || err?.response?.data?.error
          };
          dispatch(fetchAPIKeysFailed(message));
        });
    }
    dispatch(fetchAPIKeysLoading(false));
  };

export const fetchProfile = (): AppThunk => async (dispatch) => {
  dispatch(fetchProfileLoading(true));
  await httpWithJWT
    .get("/laboratories/profile")
    .then((res) => {
      const currentUser = res?.data?.data;
      dispatch(fetchProfileSuccess(currentUser));
      window.localStorage.setItem(
        "pneumaCurrentLab",
        JSON.stringify(currentUser)
      );
    })
    .catch((err) => {
      console.log("err..", err?.response?.data);
      const message = {
        message: err?.response?.data?.message || err?.response?.data?.error
      };
      dispatch(fetchProfileFailed(message));
    });
  dispatch(fetchProfileLoading(false));
};

export const updateProviderProfile =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(updateProfileLoading(true));
    await httpWithJWT
      .patch("/laboratories", payload)
      .then((res) => {
        const currentUser = res?.data?.data || {};
        dispatch(updateProfileSuccess(currentUser));
        AntdMessage.success("Profile Updated");
        dispatch(fetchProfile());
      })
      .catch((err) => {
        console.log("err..", err?.response?.data);
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(updateProfileFailed(message));
      });
    dispatch(updateProfileLoading(false));
  };

export const updateIntegrationProfile =
  (id: string | number, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(updateProfileLoading(true));
    await httpWithJWT
      .post(`/integrations/${id}`, payload)
      .then((res) => {
        const currentUser = res?.data?.data || {};
        dispatch(updateProfileSuccess(currentUser));
        AntdMessage.success("Business Profile Updated");
        dispatch(fetchProfile());
      })
      .catch((err) => {
        console.log("err..", err?.response?.data);
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(updateProfileFailed(message));
      });
    dispatch(updateProfileLoading(false));
  };

export const uploadIntegrationDocument =
  (integrationId: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(updateIntegrationDocumentsLoading(true));
    await httpWithJWT
      .post(`/integrations/${integrationId}/upload-document`, payload)
      .then((res) => {
        const document = res?.data?.data || [];
        dispatch(updateIntegrationDocumentsSuccess(document));
        AntdMessage.success("Document Uploaded Successfully");
      })
      .catch((err) => {
        console.log("err..", err?.response?.data);
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(updateProfileFailed(message));
      });
    dispatch(updateProfileLoading(false));
  };

export const fetchIntegrationDocuments =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchIntegrationDocumentsLoading(true));
    await httpWithJWT
      .get(`/integrations/${id}/documents`)
      .then((res) => {
        const documents = res?.data?.data?.documents || [];
        dispatch(fetchIntegrationDocumentsSuccess(documents));
      })
      .catch((err) => {
        console.log("err..", err?.response?.data);
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(fetchIntegrationDocumentsFailed(message));
      });
    dispatch(fetchIntegrationDocumentsLoading(false));
  };

export const requestActivation =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(requestAccountActivationLoading(true));
    await httpWithJWT
      .post(`/integrations/${id}/request-activation`)
      .then((res) => {
        const documents = res?.data?.data?.documents || [];
        dispatch(requestAccountActivationSuccess(documents));
        AntdMessage.success("Account Activation Requested");
      })
      .catch((err) => {
        console.log("err..", err?.response?.data);
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        AntdMessage.error(
          err?.response?.data?.message || err?.response?.data?.error
        );
        dispatch(requestAccountActivationFailed(message));
      });
    dispatch(requestAccountActivationLoading(false));
  };

export const activateIntegration =
  (integrationId: string): AppThunk =>
  async (dispatch) => {
    dispatch(activatingAccountLoading(true));
    try {
      const res = await httpWithJWT.post(
        `/laboratories/integrations/${integrationId}/activate`
      );
      dispatch(activatingAccountSuccess({}));
      dispatch(fetchProfile());
      AntdMessage.success("Account successfully activated ");
    } catch (err: any) {
      const message = {
        message: err?.response?.data?.message || err?.response?.data?.error
      };
      dispatch(activatingAccountFailed(message));
      AntdMessage.error(
        err?.response?.data?.message || err?.response?.data?.error
      );
    } finally {
      dispatch(activatingAccountLoading(false));
    }
  };

export const deactivateIntegration =
  (integrationId: string): AppThunk =>
  async (dispatch) => {
    dispatch(deactivatingAccountLoading(true));
    try {
      const res = await httpWithJWT.post(
        `/laboratories/integrations/${integrationId}/deactivate`
      );
      dispatch(deactivatingAccountSuccess({}));
      dispatch(fetchProfile());

      AntdMessage.success("Account successfully deactivated ");
    } catch (err: any) {
      const message = {
        message: err?.response?.data?.message || err?.response?.data?.error
      };
      dispatch(deactivatingAccountFailed(message));
      AntdMessage.error(
        err?.response?.data?.message || err?.response?.data?.error
      );
    } finally {
      dispatch(deactivatingAccountLoading(false));
    }
  };

export const logOutProvider = (): AppThunk => async (dispatch) => {
  dispatch(setLogOut());
  window.localStorage.removeItem("accessToken");
  window.localStorage.removeItem("accessKeys");
  window.localStorage.removeItem("pneumaCurrentLab");
  window.location.replace("/");
};
