import { Col, Row } from "antd";
import { Redirect, Route } from "react-router-dom";
import { OnboardingWrapper, MainWrapper } from "./styles";

const OnboardingLayout = (props: any) => {
    return (
        <OnboardingWrapper>
          <Row justify="center" align="middle" className="full-vh">
            <Col xs={20} md={16}>
              <MainWrapper>
                <Row className="inner">{props.children}</Row>
              </MainWrapper>
            </Col>
          </Row>
        </OnboardingWrapper>
    );
};

const OnboardingLayoutRoute = (props: any) => {
    const { component: Component, ...rest } = props;
    const ACCESS_TOKEN = window.localStorage.getItem("accessToken");
    return !ACCESS_TOKEN ? (
      <Route
        {...rest}
        render={(matchProps) => (
          <OnboardingLayout>
            <Component {...matchProps} />
          </OnboardingLayout>
        )}
      />
    ) : <Redirect to="/overview" />;
};

export default OnboardingLayoutRoute;