import { message } from "antd";
import Swal from "sweetalert2";
import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";

const appInstance = process.env.NODE_ENV as string;

export const baseURL =
  appInstance === "dev"
    ? "https://demo-api.pneumahealth.co"
    : "https://api.pneumahealth.co";

const accessKey = window.localStorage.getItem("accessKey");
const accessToken = window.localStorage.getItem("accessToken");

const http: AxiosInstance = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${accessKey}` }
});

http.defaults.headers.post["Content-Type"] = "application/json";

http.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    if (response) {
      if (
        response?.status === 400 &&
        response?.data?.error === "Token expired"
      ) {
        Swal.fire({
          icon: "error",
          title: "Session Expired!",
          text: "Your session token has expired, please login again!",
          confirmButtonColor: "#0f9af0"
        }).then(() => {
          logOutProvider();
        });
      }
      // return null;
    } else if (request) {
      message.error("Request failed. Please try again.");
      // return null;
    }
    return Promise.reject(error);
  }
);

export const httpWithJWT: AxiosInstance = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${accessToken}` }
});

httpWithJWT.defaults.headers.post["Content-Type"] = "application/json";

httpWithJWT.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    if (response) {
      if (
        response?.status === 400 &&
        response?.data?.error === "Token expired"
      ) {
        Swal.fire({
          icon: "error",
          title: "Session Expired!",
          text: "Your session token has expired, please login again!",
          confirmButtonColor: "#0f9af0"
        }).then(() => {
          logOutProvider();
        });
      }
      // return null;
    } else if (request) {
      message.error("Request failed. Please try again.");
      // return null;
    }
    return Promise.reject(error);
  }
);

export const httpWithJWT2: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PROVIDER_BASE_URL,
  headers: { Authorization: `Bearer ${accessToken}` }
});

httpWithJWT2.defaults.headers.post["Content-Type"] = "application/json";

httpWithJWT2.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    if (response) {
      if (
        response?.status === 400 &&
        response?.data?.error === "Token expired"
      ) {
        Swal.fire({
          icon: "error",
          title: "Session Expired!",
          text: "Your session token has expired, please login again!",
          confirmButtonColor: "#0f9af0"
        }).then(() => {
          logOutProvider();
        });
      }
      // return null;
    } else if (request) {
      message.error("Request failed. Please try again.");
      // return null;
    }
    return Promise.reject(error);
  }
);

const logOutProvider = () => {
  window.localStorage.removeItem("accessToken");
  window.localStorage.removeItem("_profile");
  window.location.replace("/");
};

export default http;
