import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import http, { httpWithJWT } from '../../utils/api'
import { message } from 'antd'

export interface LabRequestError {
    message: string
}

export interface LabRequestState {
    labRequest: any
    labRequests: Array<any>
    laboratories: Array<any>
    isLabRequestLoading: boolean
    isLabRequestsLoading: boolean
    isLaboratoriesLoading: boolean
    isCreateLabRequestLoading: boolean
    isUpdatingLabRequestStatus: boolean
    hasCreatedRequest: boolean
    hasUpdatedLabRequestStatus: boolean
    labRequestError: LabRequestError
    labRequestsError: LabRequestError,
    laboratoriesError: LabRequestError,
    createLabRequestError: LabRequestError,
    updateLabRequestStatusError: LabRequestError
}

export const initialState: LabRequestState = {
    labRequest: null,
    labRequests: [],
    laboratories: [],
    isLabRequestLoading: true,
    isLabRequestsLoading: true,
    isLaboratoriesLoading: false,
    isCreateLabRequestLoading: false,
    isUpdatingLabRequestStatus: false,
    hasCreatedRequest: false,
    hasUpdatedLabRequestStatus: false,
    labRequestError: { message: '' },
    labRequestsError: { message: '' },
    laboratoriesError: { message: '' },
    createLabRequestError: { message: '' },
    updateLabRequestStatusError: { message: '' },
}

export const labRequestsSlice = createSlice({
    name: 'labRequests',
    initialState,
    reducers: {
        // FETCH SINGLE LAB REQUEST
        fetchLabRequestLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLabRequestLoading = payload
        },
        fetchLabRequestSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.labRequest = payload;
        },
        fetchLabRequestFailed: (state, { payload }: PayloadAction<LabRequestError>) => {
            state.labRequestError = payload;
            state.labRequest = null;
        },
        // FETCH ALL LAB REQUESTS
        fetchLabRequestsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLabRequestsLoading = payload
        },
        fetchLabRequestsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.labRequests = payload;
        },
        fetchLabRequestsFailed: (state, { payload }: PayloadAction<LabRequestError>) => {
            state.labRequestsError = payload;
        },
        // FETCH ALL LAB REQUESTS
        createLabRequestLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isCreateLabRequestLoading = payload;
            state.hasCreatedRequest = false;
        },
        createLabRequestSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.labRequest = payload;
            state.hasCreatedRequest = true;
        },
        createLabRequestFailed: (state, { payload }: PayloadAction<LabRequestError>) => {
            state.createLabRequestError = payload;
            state.hasCreatedRequest = false;
        },
        // FETCH ALL LABORATORIES
        fetchLaboratoriesLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLaboratoriesLoading = payload
        },
        fetchLaboratoriesSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.laboratories = payload;
        },
        fetchLaboratoriesFailed: (state, { payload }: PayloadAction<LabRequestError>) => {
            state.laboratoriesError = payload;
        },
        // UPDATE LABORATORY REQUEST STATUS
        updateLaboratoryRequestStatusLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isUpdatingLabRequestStatus = payload
            state.hasUpdatedLabRequestStatus = false;
        },
        updateLaboratoryRequestStatusSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.labRequest = payload;
            state.hasUpdatedLabRequestStatus = true;
        },
        updateLaboratoryRequestStatusFailed: (state, { payload }: PayloadAction<LabRequestError>) => {
            state.updateLabRequestStatusError = payload;
            state.hasUpdatedLabRequestStatus = false;
        },
    },
})

export const { 
    createLabRequestLoading, createLabRequestSuccess, createLabRequestFailed, 
    fetchLabRequestLoading, fetchLabRequestSuccess, fetchLabRequestFailed, 
    fetchLabRequestsLoading, fetchLabRequestsSuccess, fetchLabRequestsFailed, 
    fetchLaboratoriesLoading, fetchLaboratoriesSuccess, fetchLaboratoriesFailed, 
    updateLaboratoryRequestStatusLoading, updateLaboratoryRequestStatusSuccess, updateLaboratoryRequestStatusFailed
} = labRequestsSlice.actions;
export const labRequestSelector = (state: { labRequests: LabRequestState }) => state.labRequests;
export default labRequestsSlice.reducer;


/** Actions */

export const createSingleLabRequest = (payload: any): AppThunk => async (dispatch) => {
    dispatch(createLabRequestLoading(true))
    await http.post(`/laboratories/requests`, payload)
        .then((res) => {
            const labRequest= res?.data?.data;
            dispatch(createLabRequestSuccess(labRequest))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(createLabRequestFailed(message));
        })
    dispatch(createLabRequestLoading(false));
}

export const fetchSingleLabRequest = (id: any): AppThunk => async (dispatch) => {
    dispatch(fetchLabRequestLoading(true))
    await http.get(`/laboratories/requests/${id}`)
        .then((res) => {
            const labRequest= res?.data?.data;
            dispatch(fetchLabRequestSuccess(labRequest))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(fetchLabRequestFailed(message));
        })
    dispatch(fetchLabRequestLoading(false));
}

export const fetchAllLabRequests = (): AppThunk => async (dispatch) => {
    dispatch(fetchLabRequestsLoading(true))
    await http.get(`/laboratories/requests`)
        .then((res) => {
            const labRequests = res?.data?.data;
            dispatch(fetchLabRequestsSuccess(labRequests));
            // message.success("LabRequests Fetched Successfully");
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(fetchLabRequestsFailed(_message));
            // message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(fetchLabRequestsLoading(false));
}

export const fetchAllLaboratories = (): AppThunk => async (dispatch) => {
    dispatch(fetchLaboratoriesLoading(true))
    await http.get(`/diagnostics/laboratories`)
        .then((res) => {
            const laboratories = res?.data?.data;
            dispatch(fetchLaboratoriesSuccess(laboratories));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(fetchLaboratoriesFailed(_message));
            // message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(fetchLaboratoriesLoading(false));
}

export const fetchAllLaboratoryInvestigations = (id: any): AppThunk => async (dispatch) => {
    dispatch(fetchLaboratoriesLoading(true))
    await http.get(`/laboratories/${id}`)
        .then((res) => {
            const laboratories = res?.data?.data;
            dispatch(fetchLaboratoriesSuccess(laboratories));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(fetchLaboratoriesFailed(_message));
            // message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(fetchLaboratoriesLoading(false));
}

export const updateLaboratoryRequestStatus = (id: any, payload: any): AppThunk => async (dispatch) => {
    dispatch(updateLaboratoryRequestStatusLoading(true))
    await http.patch(`/laboratories/requests/${id}`, payload)
        .then((res) => {
            const laboratory = res?.data?.data;
            dispatch(updateLaboratoryRequestStatusSuccess(laboratory));
            dispatch(fetchAllLabRequests());
            message.success("Status Updated Successfully");
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(updateLaboratoryRequestStatusFailed(_message));
            message.error(err?.response?.data?.error || "An error occurred, please try again");
        })
    dispatch(updateLaboratoryRequestStatusLoading(false));
}