import { Col, Form, Row, Select as AntdSelect, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "component/Button";
import Card from "component/Card";
import Input from "component/Input";
import Select from "component/Select";
import {
  authSelector,
  fetchProfile,
  updateProviderProfile,
  activateIntegration,
  deactivateIntegration
} from "redux/reducers/auth";

import { ContentWrapper, ProfileWrapper } from "./styles";

const { Option } = AntdSelect;

const Profile = () => {
  const [basiform] = Form.useForm();
  const [otherForm] = Form.useForm();
  const [testWebhookForm] = Form.useForm();
  const [liveWebhookForm] = Form.useForm();
  const dispatch = useDispatch();
  const [subDomain, setSubDomain] = useState("");

  const [isBasicInfoUpdateLoading, setIsBasicInfoUpdateLoading] =
    useState(false);
  const [isCheckedActive, setIsCheckedActive] = useState(false);
  const {
    currentUser,
    isProfileLoading,
    isActivatingAccount,
    isDeactivatingAccount
  } = useSelector(authSelector);

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  useEffect(() => {
    const { name, email, phone, imaging, address, website_url } =
      currentUser || {};
    const { street_line_one, state, city, country } = address || {};
    basiform.setFieldsValue({
      name,
      email,
      phone,
      imaging: imaging ? "Yes" : "No",
      website_url,
      street_line_one,
      state,
      city,
      country
    });
  }, [
    subDomain,
    currentUser,
    basiform,
    otherForm,
    testWebhookForm,
    liveWebhookForm
  ]);

  useEffect(() => {
    if (currentUser) {
      setIsCheckedActive(currentUser.active);
    }
  }, [currentUser]);

  async function handleAccountActivation(choice: boolean) {
    if (choice) {
      dispatch(activateIntegration(currentUser.integration));
    } else {
      dispatch(deactivateIntegration(currentUser.integration));
    }
  }

  const handleSubmitBasicDetails = async (values: any) => {
    setIsBasicInfoUpdateLoading(true);
    const { name, phone, imaging, street_line_one, city, state, country } =
      values;
    const payload: any = {
      name,
      phone,
      imaging: imaging === "Yes" ? true : false,
      address: {
        street_line_one,
        city,
        state,
        country
      }
    };
    await dispatch(updateProviderProfile(payload));
    setIsBasicInfoUpdateLoading(false);
  };

  return (
    <ProfileWrapper>
      <Row>
        <Col xs={24} md={24}>
          <ContentWrapper>
            <h2>Manage Account</h2>
            <Row justify='center'>
              <Col xs={24} md={23}>
                <Form
                  name='basicForm'
                  layout='vertical'
                  form={basiform}
                  onFinish={handleSubmitBasicDetails}
                >
                  <h3 style={{ marginTop: "25px", color: "#0f9af0" }}>
                    Business Info
                  </h3>
                  <Card hasBoxShadow={false} marginBottom={15} padding={0}>
                    <Row gutter={24}>
                      <Col span={24}>
                        <Input
                          formItem={{
                            label: "Email address",
                            name: "email",
                            rules: [
                              {
                                required: true,
                                message: "Please input your Email address"
                              },
                              {
                                type: "email",
                                message: "Please input a valid email"
                              }
                            ]
                          }}
                          mode='normal'
                          type='email'
                          label='Email address'
                          disabled
                        />
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col xs={24} md={8}>
                        <Input
                          formItem={{
                            label: "Business Name",
                            name: "name",
                            rules: [
                              {
                                required: true,
                                message: "Please input business name"
                              }
                            ]
                          }}
                          mode='normal'
                          type='text'
                        />
                      </Col>
                      <Col xs={24} md={8}>
                        <Input
                          formItem={{
                            label: "Phone number",
                            name: "phone",
                            rules: [
                              {
                                required: true,
                                message: "Please enter your phone number"
                              },
                              {
                                min: 11,
                                message:
                                  "Phone number cannot be less than 11 characters"
                              },
                              {
                                max: 11,
                                message:
                                  "Phone number cannot be greater than 11 characters"
                              },
                              {
                                pattern: /^\d+$/,
                                message: "Phone number can only contain digits"
                              }
                            ]
                          }}
                          mode='normal'
                          type='text'
                          label='Phone number'
                        />
                      </Col>
                      <Col xs={24} md={8}>
                        <Select
                          formItem={{
                            label: "Do you offer imaging diagnostic?",
                            name: "imaging",
                            rules: [
                              {
                                required: true,
                                message: "Please input value"
                              }
                            ]
                          }}
                          placeholder='Select yes or no'
                          mode='normal'
                        >
                          {["Yes", "No"].map((item) => {
                            return <Option value={item}>{item}</Option>;
                          })}
                        </Select>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col span={24}>
                        <Input
                          formItem={{
                            label: "Street Address",
                            name: "street_line_one",
                            rules: [
                              {
                                required: true,
                                message: "Please input your Street Address"
                              }
                            ]
                          }}
                          mode='normal'
                          type='text'
                          label='Street Address'
                        />
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col xs={8} md={8}>
                        <Input
                          formItem={{
                            label: "City",
                            name: "city",
                            rules: [
                              {
                                required: true,
                                message: "Please input your City"
                              }
                            ]
                          }}
                          mode='normal'
                          type='text'
                        />
                      </Col>
                      <Col xs={8} md={8}>
                        <Input
                          formItem={{
                            label: "State",
                            name: "state",
                            rules: [
                              {
                                required: true,
                                message: "Please input your State"
                              }
                            ]
                          }}
                          mode='normal'
                          type='text'
                        />
                      </Col>
                      <Col xs={8} md={8}>
                        <Input
                          formItem={{
                            label: "Country",
                            name: "country",
                            rules: [
                              {
                                required: true,
                                message: "Please input your Country"
                              }
                            ]
                          }}
                          mode='normal'
                          type='text'
                        />
                      </Col>
                    </Row>

                    <Row gutter={24} justify='start'>
                      <div className='toggle-wrapper'>
                        <span style={{ position: "relative" }}>
                          Activate account
                        </span>

                        <Form.Item>
                          <Switch
                            checked={isCheckedActive}
                            onChange={(checked) =>
                              handleAccountActivation(checked)
                            }
                            disabled={
                              isActivatingAccount || isDeactivatingAccount
                            }
                            loading={
                              isActivatingAccount || isDeactivatingAccount
                            }
                          />
                        </Form.Item>
                      </div>
                    </Row>

                    <Row gutter={24} justify='end'>
                      <Col style={{ textAlign: "right" }}>
                        <Button
                          htmlType='submit'
                          className='update-profile-btn'
                          type='primary'
                          disabled={isProfileLoading}
                        >
                          {isBasicInfoUpdateLoading
                            ? "Updating..."
                            : "Update Business Info"}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Form>
              </Col>
            </Row>
          </ContentWrapper>
        </Col>
      </Row>
    </ProfileWrapper>
  );
};

export default Profile;
