import { Col, Divider, Drawer, Row } from "antd";
import { LabRequestsDrawerWrapper, LabRequestsDrawerBody } from "./styles";
import { ClockCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleLabRequest, labRequestSelector } from "../../../../redux/reducers/labRequests";
import { useEffect } from "react";
import Loader from "../../../../component/Loader";

const LabRequestsDrawer = (props: any) => {
  const { selectedLabRequest, isVisible, onClose } = props;
  const dispatch = useDispatch();
  const { labRequest, isLabRequestLoading } = useSelector(labRequestSelector);

  useEffect(() => {
    if (selectedLabRequest) {
      dispatch(fetchSingleLabRequest(selectedLabRequest.id))
    }
  }, [dispatch, selectedLabRequest])

  const { investigations, integration } = labRequest || {};

  const { business_name, phone, email } = integration || {};

  return (
    <Drawer
      title={`Investigations`}
      width="450"
      className="lab-request-drawer"
      placement="right"
      closable={true}
      onClose={onClose}
      visible={isVisible}
    >
      <LabRequestsDrawerWrapper>
        <LabRequestsDrawerBody>
          {isLabRequestLoading ? <Loader /> : investigations?.map((investigation: any, index: number) => {
            const {
              name,
              sample_bottle,
              price,
              turn_around_time,
              category,
            } = investigation || {};
            return (
              <div className="investigation-item">
                <p className="price">NGN {(price ? (price/100) : 0).toLocaleString()}</p>
                <p className="name">{name}</p>
                <Row gutter={20} style={{marginTop: '10px'}}>
                  <Col span={24}>
                    <span className="category tag">Category: {category}</span>
                  </Col>
                  <Col span={24}>
                    <span className="turn_around_time tag"> <ClockCircleOutlined /> {turn_around_time}</span>
                    <span className="category tag">Sample Bottle: {sample_bottle}</span>
                  </Col>
                </Row>
              </div>
            );
          })}
          <Divider />
          <h2>Partner Details</h2>
          <p><b>Name:</b> {business_name}</p>
          <p><b>Phone:</b> {phone}</p>
          <p><b>Email:</b> {email}</p>
        </LabRequestsDrawerBody>
      </LabRequestsDrawerWrapper>
    </Drawer>
  );
};

export default LabRequestsDrawer;
