import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http, { httpWithJWT } from "../../utils/api";
import { message } from "antd";
import { requestAccountActivationFailed } from "./auth";

export interface UserError {
  message: string;
}

export interface UserState {
  user: any;
  users: Array<any>;
  isUserLoading: boolean;
  isUsersLoading: boolean;
  isCreateUserLoading: boolean;
  isVerifyUserLoading: boolean;
  isRequestUserAccessLoading: boolean;
  hasCreatedUser: boolean;
  hasVerifiedUser: boolean|null;
  hasRequestedUserAccess: boolean;
  userError: UserError;
  usersError: UserError;
  createUserError: UserError;
  verifyUserError: UserError;
  requestUserAccessError: UserError;
}

export const initialState: UserState = {
  user: null,
  users: [],
  isUserLoading: true,
  isUsersLoading: true,
  isCreateUserLoading: false,
  isVerifyUserLoading: false,
  isRequestUserAccessLoading: false,
  hasCreatedUser: false,
  hasVerifiedUser: null,
  hasRequestedUserAccess: false,
  userError: { message: "" },
  usersError: { message: "" },
  createUserError: { message: "" },
  verifyUserError: { message: "" },
  requestUserAccessError: { message: "" }
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // CREATE USER
    createUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreateUserLoading = payload;
      state.hasCreatedUser = false;
    },
    createUserSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.user = payload;
      state.hasCreatedUser = true;
    },
    createUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.createUserError = payload;
      state.user = null;
      state.hasCreatedUser = false;
    },
    // VERIFY USER
    resetHasVerifiedUser: (state) => {
        state.hasVerifiedUser = null;
    },
    verifyUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isVerifyUserLoading = payload;
    },
    verifyUserSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.hasVerifiedUser = true;
    },
    verifyUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.verifyUserError = payload;
      state.hasVerifiedUser = false;
    },
    // REQUEST USER ACCESS
    requestUserAccessLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isRequestUserAccessLoading = payload;
      state.hasRequestedUserAccess = false;
    },
    requestUserAccessSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.hasRequestedUserAccess = true;
    },
    requestUserAccessFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.requestUserAccessError = payload;
      state.hasRequestedUserAccess = false;
    },
    // FETCH SINGLE USER
    fetchUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUserLoading = payload;
    },
    fetchUserSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.user = payload;
    },
    fetchUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.userError = payload;
      state.user = null;
    },
    // FETCH ALL USERS
    fetchUsersLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUsersLoading = payload;
    },
    fetchUsersSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.users = payload;
    },
    fetchUsersFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.usersError = payload;
    },
  },
});

export const {
  createUserLoading,
  createUserSuccess,
  createUserFailed,
  resetHasVerifiedUser,
  verifyUserLoading,
  verifyUserSuccess,
  verifyUserFailed,
  requestUserAccessLoading,
  requestUserAccessSuccess,
  requestUserAccessFailed,
  fetchUserLoading,
  fetchUserSuccess,
  fetchUserFailed,
  fetchUsersLoading,
  fetchUsersSuccess,
  fetchUsersFailed,
} = usersSlice.actions;
export const userSelector = (state: { users: UserState }) => state.users;
export default usersSlice.reducer;

/** Actions */

export const createUser =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(createUserLoading(true));
    await http
      .post(`/users`, payload)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(createUserSuccess(user));
        dispatch(fetchAllUsers())
        message.success("User created successfully");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(createUserFailed(message));
      });
    dispatch(createUserLoading(false));
  };

export const verifyUser =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(verifyUserLoading(true));
    await http
      .post(`/users/verify`, payload)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(verifyUserSuccess(user));
        // message.success("User verified successfully");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(verifyUserFailed(message));
      });
    dispatch(verifyUserLoading(false));
  };

export const requestUserAccess =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(requestUserAccessLoading(true));
    await http
      .post(`/users/request_access`, payload)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(requestUserAccessSuccess(user));
        message.success("User access requested successfully");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(requestAccountActivationFailed(message));
      });
    dispatch(requestUserAccessLoading(false));
  };

  export const approveUserAccessRequest =
  (otp: string): AppThunk =>
  async (dispatch) => {
    dispatch(createUserLoading(true));
    await http
      .post(`/users/approve_request/${otp}`)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(createUserSuccess(user));
        message.success("User successfully onboarded");
      })
      .catch((err) => {
        const messages = {
          message: err?.response?.data?.message || "An error occurred",
        };
        message.error("Wrong OTP! Please go back and request OTP again");
        dispatch(createUserFailed(messages));
      });
    dispatch(createUserLoading(false));
  }

export const fetchSingleUser =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchUserLoading(true));
    await http
      .get(`/users/${id}`)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(fetchUserSuccess(user));
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(fetchUserFailed(message));
      });
    dispatch(fetchUserLoading(false));
  };

export const fetchAllUsers = (): AppThunk => async (dispatch) => {
  dispatch(fetchUsersLoading(true));
  await httpWithJWT
    .get(`/users`)
    .then((res) => {
      const users = res?.data?.data;
      dispatch(fetchUsersSuccess(users));
      // message.success("Users Fetched Successfully");
    })
    .catch((err) => {
      const _message = {
        message: err?.response?.data?.message || "An error occurred",
      };
      dispatch(fetchUsersFailed(_message));
      message.error(err?.response?.data?.message || "An error occurred");
    });
  dispatch(fetchUsersLoading(false));
};
