import 'antd/dist/antd.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import { ROUTES } from "./constants/routes";
// Layouts
import DashboardLayoutRoute from "./layouts/DashboardLayout";
import OnboardingLayoutRoute from "./layouts/OnboardingLayout";
import Claims from "./screens/Claims";
import Dashboard from "./screens/Dashboard";
import Investigations from "./screens/Investigations";
import LabRequests from "./screens/LabRequests";
import EnterOTP from "./screens/Onboarding/EnterOTP";
// Onboarding Screens
import Login from "./screens/Onboarding/Login";
import RecoverPassword from "./screens/Onboarding/RecoverPassword";
import Register from "./screens/Onboarding/Register";
import RequestOTP from "./screens/Onboarding/RequestOTP";
import ResetPassword from "./screens/Onboarding/ResetPassword";
import TermsAndConditions from "./screens/Onboarding/TermsAndConditions";
// Dashboard Screens
import Profile from "./screens/Profile";
import Transactions from "./screens/Transactions";

const Error404Page = () => (
  <>
    <h4>Error 404 Page</h4>
  </>
)


const App = () => {
  return (
    <BrowserRouter>
      <Switch>
          <OnboardingLayoutRoute exact path={ROUTES.HOMEPAGE} component={Login} />
          <OnboardingLayoutRoute exact path={ROUTES.LOGIN} component={Login} />
          <OnboardingLayoutRoute exact path={ROUTES.REGISTER} component={Register} />
          <OnboardingLayoutRoute exact path={ROUTES.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
          <OnboardingLayoutRoute exact path={ROUTES.RECOVER_PASSWORD} component={RecoverPassword} />
          <OnboardingLayoutRoute exact path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
          <OnboardingLayoutRoute exact path={ROUTES.REQUEST_OTP} component={RequestOTP} />
          <OnboardingLayoutRoute exact path={ROUTES.ENTER_OTP} component={EnterOTP} />
          
          {/* Dashboard Screens */}
          <DashboardLayoutRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
          <DashboardLayoutRoute exact path={ROUTES.CLAIMS} component={Claims} />
          <DashboardLayoutRoute exact path={ROUTES.MANAGE_ACCOUNT} component={Profile} />
          <DashboardLayoutRoute exact path={ROUTES.LAB_INVESTIGATIONS} component={LabRequests} />
          <DashboardLayoutRoute exact path={ROUTES.INVESTIGATIONS} component={Investigations} />
          <Route component={Error404Page} />
        </Switch>
    </BrowserRouter>
  );
}

export default App;
