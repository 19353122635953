import styled from 'styled-components';

interface MenuItemProps {
    active: boolean;
}


const SidebarWrapper = styled.aside`
    height: calc(100vh - 57px);
    margin-top: 57px;
    width: 270px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: left;
    z-index: 2;
    position: fixed;
    left: 0;
    top: 0;
    box-shadow: 2px 0px 5px rgba(0, 120, 226, 0.15);
    .icon {
        width: 45px;
    }
    .dashboard-mode-switch {
        position: absolute;
        bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        cursor: pointer;
        color: #0f9af0;
        i {
            margin-right: 10px;
        }
    }
    @media (max-width: 1024px) {
        display: none;
    }
`;

const SidebarMenuList = styled.ul`
    list-style-type: none;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
`;

const SidebarMenuListItem = styled.li<MenuItemProps>`
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    text-align: left;
    margin: 15px 0px;
    background: ${props => props.active ? '#f2f2f2' : '#fff'};
    padding: 10px;
    border-radius: 8px;

    a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: ${props => props.active ? '#0f9af0' : '#002e46'};
    }
    svg {
        margin-right: 15px;
    }
`;

export { SidebarWrapper, SidebarMenuList, SidebarMenuListItem }