import { Col, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../component/Button";
import Card from "../../../../component/Card";
import DatePicker from "../../../../component/DatePicker";
import Search from "../../../../component/Search";
import {
  fetchAllLabRequests,
  labRequestSelector
} from "../../../../redux/reducers/labRequests";
import { ClaimsTableBody, ClaimsTableWrapper } from "./styles";
import Input from "../../../../component/Input";

interface ClaimsTableProps {
  count?: number;
  hasPagination?: boolean;
  marginTop?: number;
  dashboardMode?: string;
}

const ClaimsTable = ({ count, dashboardMode }: ClaimsTableProps) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    patient: "",
    partner: "",
    date: new Date()
  });
  const { labRequests, isLabRequestsLoading } = useSelector(labRequestSelector);
  const [claimsState, setClaimsState] = useState<any[]>([]);
  const [claimsStatePerMonth, setClaimsStatePerMonth] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<any>();
  const profile = JSON.parse(localStorage.getItem("pneumaCurrentLab") || "");
  const { name } = profile || {};

  useEffect(() => {
    dispatch(fetchAllLabRequests());
  }, [dispatch]);

  useEffect(() => {
    setClaimsState(labRequests.filter((item) => item.status === "completed"));
    setClaimsStatePerMonth(
      labRequests.filter((item) => item.status === "completed")
    );
  }, [labRequests]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return <p>{id}</p>;
      }
    },
    {
      title: "Patient's Name",
      dataIndex: "name",
      key: "first_name",
      width: 200,
      sorter: (a: any, b: any) =>
        a?.user?.first_name.toLowerCase() < b?.user?.first_name.toLowerCase()
          ? -1
          : a?.user?.first_name.toLowerCase() >
            b?.user?.first_name.toLowerCase()
          ? 1
          : 0,
      render: (_: string, record: any) => {
        const { first_name, last_name } = record?.user || {};
        return (
          <div className='flex align-center' style={{ alignItems: "center" }}>
            <div>
              <p style={{ margin: 0 }}>
                {first_name} {last_name}
              </p>
            </div>
          </div>
        );
      }
    },
    {
      title: "Partner",
      dataIndex: "partner",
      key: "partner",
      render: (_: any, record: any) => {
        const { business_name } = record?.integration || {};
        return <p>{business_name}</p>;
      }
    },
    {
      title: "Amount",
      dataIndex: "total_price",
      key: "total_price",
      align: "center" as "center",
      render: (total_price: number) => {
        return (
          <p className='tags' style={{ textTransform: "capitalize" }}>
            NGN {(total_price ? total_price / 100 : 0).toLocaleString()}
          </p>
        );
      }
    },
    {
      title: "Created on",
      dataIndex: "schedule",
      key: "schedule",
      render: (_: Date, record: any) => {
        return (
          <>
            <p>{moment(record.created_at).format("LL")}</p>
            <span className='subtext'>
              {moment(record.created_at).format("hh:mm a")}
            </span>
          </>
        );
      }
    }

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   align: "center" as "center",
    //   render: (_: string, record: any) => {
    //     return (
    //       <Button type="secondary" onClick={() => handleOpenDrawer(record)}>
    //         View details
    //       </Button>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    const _labRequests = [...labRequests];
    const { partner, patient, date } = filter;
    const val = _labRequests.filter(
      ({ user, integration, status, createdAt }: any) => {
        const fullName = `${user.first_name} ${user.last_name}`;
        const businessName = `${integration?.business_name}`;
        return (
          status === "completed" &&
          businessName.toLowerCase().includes(partner.toLowerCase()) &&
          fullName.toLowerCase().includes(patient.toLowerCase()) &&
          moment(createdAt).isSame(moment(date), "month") &&
          moment(createdAt).isSame(moment(date), "year")
        );
      }
    );
    setClaimsState(val);
  }, [filter, labRequests]);

  const handlePatientSearch = (e: any) => {
    const keyword = e.target.value;
    setFilter({ ...filter, patient: keyword });
  };

  const handlePartnerSearch = (e: any) => {
    const keyword = e.target.value;
    setFilter({ ...filter, partner: keyword });
  };

  const onChange = (date: any, dateString: string) => {
    if (date && dateString) {
      const _labRequests = [...labRequests];
      setSelectedMonth(dateString);
      setClaimsState(
        _labRequests.filter((item) => {
          const { created_at } = item;
          return (
            moment(created_at).isSame(moment(date), "month") &&
            moment(created_at).isSame(moment(date), "year")
          );
        })
      );
    } else {
      setSelectedMonth("");
      setClaimsState(labRequests);
    }
  };

  const totalAmountPerMonth = claimsState.reduce((acc, item) => {
    acc += item.total_price / 100;
    return acc;
  }, 0);
  const totalCount =
    claimsState.length === claimsStatePerMonth.length
      ? `${claimsState.length}`
      : `${claimsState.length}/${claimsStatePerMonth.length}`;

  const dataSource = count ? claimsState.slice(0, count) : claimsState;

  const downloadableLabRequestsState = claimsState.map(
    ({ id, user, integration, status, total_price, currency, created_at }) => {
      return {
        id,
        patient: `${user?.first_name} ${user?.last_name}`,
        partner: integration?.business_name,
        status,
        total_price,
        currency,
        created_at
      };
    }
  );

  return (
    <>
      {!count && (
        <Row justify='space-between' align='middle'>
          <Col xs={24} md={12}>
            <h3 style={{ fontWeight: "500" }}>
              {totalCount} {+totalCount > 1 ? "Claims" : "Claim"}
            </h3>
          </Col>
          <Col xs={24} md={12}>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <Search
                  placeholder="Search by user's name"
                  onChange={handlePatientSearch}
                />
              </div>
            </div>
          </Col>
        </Row>
      )}
      <Row
        gutter={10}
        style={{ marginTop: "20px", marginBottom: "0px" }}
        justify='start'
        align='middle'
      >
        <Col xs={24} md={4} style={{ marginBottom: 10 }}>
          <DatePicker
            marginBottom={0}
            mode='normal'
            width='auto'
            format='MMM YYYY'
            onChange={onChange}
            picker='month'
          />
        </Col>
        <Col xs={24} md={4} style={{ marginBottom: 10 }}>
          <Input
            formItem={{
              name: "partner",
              rules: [
                {
                  required: true,
                  message: "Please select partner"
                }
              ]
            }}
            showSearch={true}
            onChange={handlePartnerSearch}
            marginBottom={0}
            optionFilterProp='children'
            placeholder='Search Partner'
            label=''
            mode='normal'
            required
          />
        </Col>
        <Col xs={24} md={8}></Col>
        <Col xs={24} md={8} style={{ textAlign: "right", marginBottom: 10 }}>
          <CSVLink
            data={downloadableLabRequestsState}
            filename={`${name}_claims_${moment(selectedMonth).format(
              `MMMM`
            )}_${moment(selectedMonth).format(`YYYY`)}.csv`}
          >
            <Button style={{ height: "43px" }} type='primary'>
              Download as CSV
            </Button>
          </CSVLink>
        </Col>
        <Col xs={24} md={8} style={{ marginBottom: 10 }}>
          <h3 style={{ margin: 0 }}>
            Amount Payable: NGN {totalAmountPerMonth.toLocaleString()}
          </h3>
        </Col>
      </Row>
      <Card marginBottom={15} padding={0} style={{ marginTop: "0px" }}>
        <ClaimsTableWrapper>
          <ClaimsTableBody>
            <Table
              dataSource={claimsState}
              columns={columns}
              scroll={
                !isLabRequestsLoading && dataSource.length > 0 ? { x: 700 } : {}
              }
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              loading={isLabRequestsLoading}
              pagination={{ position: ["bottomRight"], showLessItems: true }}
            />
          </ClaimsTableBody>
        </ClaimsTableWrapper>
      </Card>
    </>
  );
};

export default ClaimsTable;
